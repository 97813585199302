import {CarriageObj, CoilingObj, CostingObj, FinishingObj, InspectionObj, OtherObj, PackingObj} from "@/store/CostingObj";
import {nextTick} from "vue";
// import {isNumeric} from "@/mixins";
import store from "@/store/index";
import Cookie from "js-cookie";

export class WireformCostingObj extends CostingObj {
    MaterialCost = null
    WireSource = null  //  2, is stock, 3 is on order
    FMMaterialCost = null
    PartCost = null
    PriceByKg = 'K'
    FMStockMaterial = null
    CostingMethod = 3 // default margins
    ProductionRate = null   // how many can we make in an hour?
    HourRate = null

    constructor(part) {
        super();
        this.Part = part;
        this.Coiling = new CoilingObj()  // wireforms must be coiled
        this.Carriage = new CarriageObj()
        this.Finishing = new FinishingObj()
        this.Inspection = new InspectionObj()
        this.Other = new OtherObj()
        this.Packing = new PackingObj()
        this.AncillaryItems = []  // empty list to add object to
    }


    async validateWireformCosting(opt, max_dp) {
        await nextTick();
        this.Part.calcWfLength2()
        this.Part.calcWfWeight()
        // lose any spare decimal places
        if (opt && max_dp !== undefined) {
            this[opt] = Math.round(this[opt] * Math.pow(10, max_dp)) / Math.pow(10, max_dp);
        }

        var cost_check = this.checkCostingViable()
        console.log("costingObj, checking viable", this.Qty1, cost_check)
        if (cost_check === 1) {  // why was this 1? 31/7/24
            // clear out any quantities that may have had values in but are now empty
            // for (var i = 1; i < 6; i++) {
            //     // quantities
            //     if (this['Qty' + i] == null || this['Qty' + i] === 0 ||
            //         this['Qty' + i].length === 0 || !isNumeric(this['Qty' + i])) {
            //         this['Qty' + i] = null;
            //     }
            //     // watch out for deleted cells in Sellcost 1-5
            //     if (this['SellCost' + i] == null || this['SellCost' + i] === 0 ||
            //         this['SellCost' + i].length === 0 || !isNumeric(this['SellCost' + i])) {
            //         this['SellCost' + i] = null;
            //     }
            //     if (this.Peening['PeenCost' + i] == null || this.Peening['PeenCost' + i] === 0 ||
            //         this.Peening['PeenCost' + i].length === 0 || !isNumeric(this.Peening['PeenCost' + i])) {
            //         this.Peening['PeenCost' + i] = null;
            //     }
            //     if (this['HeatCost' + i] == null || this['HeatCost' + i] === 0 ||
            //         this['HeatCost' + i].length === 0 || !isNumeric(this['HeatCost' + i])) {
            //         this['HeatCost' + i] = null;
            //     }
            //
            //     if (this['FinishingCost' + i] == null || this['FinishingCost' + i] === 0 ||
            //         this['FinishingCost' + i].length === 0 || !isNumeric(this['FinishingCost' + i])) {
            //         this['FinishingCost' + i] = null;
            //     }
            // }

            // let dataWaiting = true;
            let costingURL = `/sales/calculate_wireform_costs/`;
            if (this.Qty1) {
                const site = store.getters.CurrentSite;

                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                    },
                    body: JSON.stringify({costing: this, 'site': site})
                };
                await fetch(costingURL, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log("BACK FROM CALCULATING WIREFORM COSTS")
                        //Update ourselves with the new values
                        for (const [property, value] of Object.entries(data.return_costing)) {
                            if (value === null) continue; //Don't null out fields
                            this[property] = value
                        }
                        // this.Coiling = data.return_coiling
                        //
                        // if (data.return_heat) {
                        //     this.Heat = data.return_heat
                        // }
                        this.Results = data.costing_results

                    });
            }
        }
    }    // end of validate costing
}