import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import store from './store'
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from 'primevue/accordiontab';
import Badge from "primevue/badge";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from 'primevue/fileupload';
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import MenuBar from 'primevue/menubar';
import MultiSelect from "primevue/multiselect";
import ProgressBar from "primevue/progressbar";
import RadioButton from "primevue/radiobutton";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import SpeedDial from "primevue/speeddial";
import Textarea from "primevue/textarea";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip"
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";

import VueKonva from "vue-konva"
import convert from "convert-units";
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "./assets/css/jbs_common.css"
import "./assets/css/jbs_parts.css"
import "flag-icons/css/flag-icons.min.css";

import en from './locales/en.json'
import fr from './locales/fr.json'
import pl from './locales/pl.json'

import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';


const numberFormats = {
    'en': {
        two_zeros: {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
        three_zeros: {style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3},
        // currency_three: {style: 'currency', currency: 'GBP', minimumFractionDigits: 3, maximumFractionDigits: 3},
        currency: {
            style: 'currency', currency: 'GBP', notation: 'standard'
        },
    },
    'fr': {
        two_zeros: {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
        three_zeros: {style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3},
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
    },
    'pl': {
        two_zeros: {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
        three_zeros: {style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3},
                currency: {
            style: 'currency', currency: 'PLN', notation: 'standard'
        },
    }
}
const i18n = createI18n({
    legacy: false,
    globalInjection: true, // Adds the $i18n, $t, etc. to all templates
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        fr,
        pl,
    },
    numberFormats,
});

dayjs.extend(customParseFormat);

const app = createApp(App).use(store);
app.config.unwrapInjectedRef = true;

app.use(router);
app.use(PrimeVue);
app.use(VueKonva);

app.use({
    install() {
        app.config.globalProperties.isNumberKey = evt => {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        };
        app.config.globalProperties.units = (value, from, to, includeLabel = false) => {
            try {
                return convert(value).from(from).to(to) + (includeLabel ? ' ' + to : '');
            } catch (err) {
                // Prevent Vue from crashing if incorrect metrics are provided
                // and simply return the original value instead.
                console.error(err);
                return value + (includeLabel ? ' ' + from : '');
            }
        };
    }
});

app.use(PrimeVue, {
    locale: {
        //This covers supplying translations just for PrimeVue's own built in strings
        //The full list of which can be found here: https://primefaces.org/primevue/showcase/#/locale

        //That being said... until everything has switched over to Vue-i18n some improvising can be done
        ...Object.fromEntries(Object.entries(i18n.global.messages.value.en).map(([k, v]) => [k, v.source])),
    }
});

app.component('AutoComplete', AutoComplete);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Badge', Badge)
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('FileUpload', FileUpload);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('MenuBar', MenuBar);
app.component('MultiSelect', MultiSelect)
app.component('ProgressBar', ProgressBar)
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('SpeedDial', SpeedDial);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('ToggleButton', ToggleButton);
app.directive('tooltip', Tooltip);
app.use(i18n);
app.use(ConfirmationService);
app.use(ToastService)
app.mount('#app');
