export class QuoteObj {
        id = null
        customer = ''
        customer_id = null
        customer_code = ''
        customer_contact_id = null
        customer_contact = ''
        customer_phone = ''
        customer_email = ''
        customerRef = ''
        qAddress = null;
        quote_address = ''
        terms = null
        deliveryPeriod = null
        carriage = false
        vat = false
        status = 'U'  // initially unchecked
        hot_status= false  // gets 'sticky' once changes from hot to something else
        quoteBy = ''
        checked_by = ''
        managed_by = ''
        enquirySource = ''
        chaseDate = ''
        chaseMethod = ''
        internalComments = ''
        customerComments = ''
        sales_notes = ''
        currency = ''  // defaults to site currency
        exchange_rate = 1
        reasonClosed = null
        quote_reviewed = false  // budget only or price re-checked
        market_sector= null  // for analysis
        parts = []
        quote_documents = []
}