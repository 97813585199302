import {CarriageObj, CostingObj, FinishingObj, InspectionObj, OtherObj, PackingObj} from "@/store/CostingObj";
import store from "@/store/index";
import Cookie from "js-cookie";

export class LasercutCostingObj extends CostingObj {
    sheet_cost = null
    setup_time = null
    run_time_sheet = null
    sheets_required = null
    lasercut_margin = null
    hourly_rate = null
    lasercut_hourly_rate = null
    // ops are now separate from the object as we don't often need them
    op1_rate = null
    op1_cost = null
    op2_rate = null
    op2_cost = null
    op3_rate = null
    op3_cost = null
    op4_rate = null
    op4_cost = null
    op5_rate = null
    op5_cost = null
    op6_rate = null
    op6_cost = null

    constructor(part) {
        super();
        this.Part = part;
        this.Finishing = new FinishingObj()
        this.Inspection = new InspectionObj()
        this.Packing = new PackingObj()
        this.Carriage = new CarriageObj()
        this.Other = new OtherObj()
    }

    setMargins(rates, margins) {
        this.lasercut_hourly_rate = rates.find(rate => rate.process === 'laser_cutting').base_rate;
        super.setMargins(rates, margins);
    }

    async validateLaserCosting() {
        this.Part.cutting_time = this.setup_time + (this.sheets_required * this.run_time_sheet);
        if (this.Part.Sheet_length &&
            this.Part.Sheet_width &&
            this.Part.Sheet_thickness &&
            this.Part.PartMaterial) {

            const sheet_volume = this.Part.Sheet_weight = this.Part.Sheet_length
                * this.Part.Sheet_width * this.Part.Sheet_thickness / 1000000000 //m3

            if (this.PartMaterial === 'SS') {
                this.Part.Sheet_weight = sheet_volume * 7500  // density = 7500ks/m3
            } else { // Mild Steel
                this.Part.Sheet_weight = sheet_volume * 7840
            }
            this.Part.Sheet_weight = +this.Part.Sheet_weight.toFixed(4);

            if (this.sheet_cost &&
                this.Part.qty_per_sheet &&
                this.setup_time &&
                this.run_time_sheet) {

                if (this.Qty1) {
                    let costingURL = `/sales/calculate_laser_costs/`;
                    const site = store.getters.CurrentSite;
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                        },
                        body: JSON.stringify({costing: this, 'site': site})
                    };
                    fetch(costingURL, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            //Update ourselves with the new values
                            /*for (let [property, value] of Object.entries(data.return_costing)) {
                                if (value === null) continue; //Don't null out fields
                                if (['sheet_cost', 'setup_time', 'run_time_sheet', 'sheets_required',
                                    'lasercut_margin', 'hourly_rate', 'lasercut_hourly_rate'].includes(property)) {
                                    value = +value;
                                }
                                this[property] = value
                            }*/
                            // look for the UnitCost values in results and put back in the Costing
                            // these values need to be stored on teh costing object for the PDF
                            this.Results = data.costing_results

                            for (let [property, value] of Object.entries(data.return_costing)) {
                                if (property.includes('UnitCost') || property.includes('SellCost')) {
                                    this[property] = value
                                }
                            }
                        });
                }
                return 1
            }
        }
    }
}