import {calculationRelevant} from "@/store/decorators";
import {PartObj} from "@/store/PartObj";

export class WireformObj extends PartObj {
    @calculationRelevant
    PartType = "W"       // wireform
    PartMaterial = 187         // incorporate mild steel from the boolean, MS by default
    WireDiameter = null
    Complex = false  // we're not doing a 3D drawing then
    WfStructure = []     // a bunch of BendObjs
    @calculationRelevant
    Units = 'M'     // metric by default, always
    grade_opts = {}
    Results = {};
    // flags for what details appear on shop floor print out.  Set the smarties on screen
    wireformquoteflags = {
        sfFinish: true,
        sfMaterial: false,
        sfMaterialSpec: true,
        sfWireDiameter: 0,
// for quotes
        qFinish: true,
        qMaterial: false,
        qMaterialSpec: true,
        qWireDiameter: 1,
    }

    calcWfLength2() {
        // just calculates the length
        let totalLength = 0;
        let element_length = 0
        for (let i = 0; i < this.WfStructure.length; i++) {
            element_length = Math.abs(parseFloat((+this.WfStructure[i]['actual_length'])))
            totalLength = totalLength + element_length
        }
        // update the displayed length
        this.wfTotalLength = totalLength;
    }

    calcWfWeight() {
      if (!this.density) return; //Don't have a density to go off of
      let totalLength = +this.wfTotalLength;
      let radius = this.WireDiameter / 2.0;
      let volume = Math.PI * radius * radius * totalLength;
      this.wfMass = (this.density * volume * 1000).toFixed(2);
    }
}

export class BendObj { // wireInfo on old version
    actual_length = null    // either the straight length or the length of teh arc
    summary = null          // for display purposes
    length = null           // straight length, no bends
    radius = null           // combines with angle to give an arc
    angle = null            // combines with radius to give an arc
}

