6

export class CostingObj {
    id = null
    Part = []
    Qty1 = null
    Qty2 = null
    Qty3 = null
    Qty4 = null
    Qty5 = null
    CostingMethod = 3 // default margins
    q1UnitCost = null
    q2UnitCost = null
    q3UnitCost = null
    q4UnitCost = null
    q5UnitCost = null
    q1SellCost = null
    q2SellCost = null
    q3SellCost = null
    q4SellCost = null
    q5SellCost = null
    ConfirmTooling = false
    ToolingSeparate = false
    ToolingCost = null
    TimeMargin = null
    FinishingMargin = null
    MaterialMargin = null
    WasteMargin = null
    CarriageMargin = null
    ConfirmOps = false
    ProductionOps = []  // a list of Ops Objects
    ConfirmAncillaryItems = false
    AncillaryItems = []  // a list of extra parts (nuts & bolts)
    Results = {}

    setMargins(rates, margins) {
        //TODO: Is a custom hour rate going to be getting squished here? 18/12/2024 (See also CostMethod)
        this.HourRate = rates.find(rate => rate.process === 'general').base_rate;
        if (this.CostingMethod !== 4) {
            for (const el of margins) {
                switch (el.process) {
                    case 'time':
                        this.TimeMargin = el.margin;
                        break;
                    case 'finishing':
                        this.FinishingMargin = el.margin;
                        break;
                    case 'wire':
                        this.MaterialMargin = el.margin;
                        break;
                    case 'waste':
                        this.WasteMargin = el.margin;
                        break;
                    case 'carriage':
                        this.CarriageMargin = el.margin;
                        break;
                    case 'laser_cut':
                        this.lasercut_margin = el.margin;
                        break;
                }
            }
        }
    }

    validateCosting() {
        switch (this.Part.PartType) {
            case 'C':
            case 'E':
            case 'T':
                return this.validateSpringCosting();
            case 'W':
                // TODO: add wireform routine
                return this.validateWireformCosting();
            case 'L':
                // TODO: add lasercut routine
                return this.validateLaserCosting();
            case 'X':
                // TODO: add bought in routine
                return this.validateBoughtinCosting();
            case 'F':
                // TODO: sometime in the future...
                return this.validateFlatSpringCosting();
            case 'K':
                // TODO: sometime in the future...
                return this.validateConstantForceCosting();
        }
    }

    checkCostingViable() {
        let errors = ""

        if (this.Part.PartType === 'X') { // bought in parts
            return 1;
        } // end of  parts test

        if (this.Part.PartType === 'L') {
            // we need sheet dimensions, cost per sheet, qty per sheet, setup & run time
            // console.log("PARTS on 434", this.parts[0])
            if (this.parts[0]['itemLength'] &&
                this.parts[0]['itemWidth'] &&
                this.parts[0]['itemThickness'] &&
                this.parts[0]['material']) {

                var sheet_volume = this.Sheet_weight = this.parts[0]['itemLength']
                    * this.parts[0]['itemWidth'] * this.parts[0]['itemThickness']
                    / (1000 * 1000 * 1000)  // m3

                if (this.parts[0]['material'] === 'Stainless Steel') {
                    this.Sheet_weight = sheet_volume * 7500  // density = 7500kg/m3
                } else { // Mild Steel
                    this.Sheet_weight = sheet_volume * 7840
                }

                if (this.parts[0].qty_per_sheet) {
                    // assume 5% wastage
                    var weight = 0.95 * this.Sheet_weight / this.parts[0]['qty_per_sheet']
                    this.parts[0]['itemWeight'] = weight.toFixed(4)
                }
                this.Sheet_weight = this.Sheet_weight + 'kg'

                if (this.parts[0]['itemCost'] &&
                    this.parts[0]['qty_per_sheet'] &&
                    this.parts[0]['setup_time'] &&
                    this.parts[0]['run_time']) {
                    // console.log("laser cut is valid")
                    return 1
                }
            } else {
                return 0
            }
        } // end of laser cut parts test

        if (this.Part.PartType === 'W') { // wireform
            if (this.Part.WfStructure.length > 0) {
                return 1;
            }
        } // end of wireform test


        // SPRINGS
        if (this.Part.PartType === 'C') {
            if (!this.Part.Results.helix_angle && !this.Part.WireGrade && this.Part.WireGrade > 0) {
                console.error('here we are')
                return 0;  // all good
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of compression tests

        if (this.Part.PartType === 'E') {
            if (this.Part.ExtEstimatedLength && this.Part.WireGrade && this.Part.WireGrade > 0) {
                return 0;  // all good
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of extension tests

        if (this.Part.PartType === 'T') {
            if (this.Part.TotalCoils && this.Part.WireGrade && this.Part.WireGrade > 0) {
                return 0;  // all good
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of torsion tests

        if (errors.length > 0) {
            console.log("Costing has errors", errors)
            // var thisTitle = 'Costing Validation';
            // scope.callDialog(thisTitle, errors);
        }
        console.log("error check", errors)
        return 0;
    }
}

export class CoilingObj {
    CoilingMachine = null
    SetupManual = null
    RunningManual = null
}

export class FinishingObj {
    ConfirmFinishing = false
    Finisher = null
    FinishingUnitCost = null
    FinishingRate = null
    FinishingCarriage = null
    ManualFinishingCost1 = null
    ManualFinishingCost2 = null
    ManualFinishingCost3 = null
    ManualFinishingCost4 = null
    ManualFinishingCost5 = null
}

export class InspectionObj {
    ConfirmInspection = false
    InspectionType = null
    InspectionSetup = null
    InspectionRate = null
    InspectionCost1 = null
    InspectionCost2 = null
    InspectionCost3 = null
    InspectionCost4 = null
    InspectionCost5 = null
}

export class PackingObj {
    ConfirmPackaging = false
    ManualPacking = false
    PackagingSetup = null
    PackagingRate = null
    PackingCost1 = null
    PackingCost2 = null
    PackingCost3 = null
    PackingCost4 = null
    PackingCost5 = null
}

export class CarriageObj {
    ConfirmCarriage = false
    Courier = null
    CarriageSetup = null
    CarriageRate = null
    CarriageCost1 = null
    CarriageCost2 = null
    CarriageCost3 = null
    CarriageCost4 = null
    CarriageCost5 = null
}

export class OtherObj {
    ConfirmOther = false
    ManualOther = false
    OtherType = null
    OtherSetup = null
    OtherRate = null
    ManualOtherCost1 = null
    ManualOtherCost2 = null
    ManualOtherCost3 = null
    ManualOtherCost4 = null
    ManualOtherCost5 = null
}

export class ExtrasObj {
    // catch all for any extra pricing
}

export class ProductionOpsObj {
    // end forming, bending, assembly...
    Op = null
    OpName = null
    SetupTime = null
    RunningTime = null
    RunningPerQuantity = 100
    OpCost = null
}

export class AncillaryItemsObj {
    // nuts & bolts sold to complete the part
    itemCode = null
    itemDescription = null
    itemSupplier = null
    itemLeadTime = null
    itemQuantity = null
    itemCost = null

    get total() {
        return this.Qty * this.Cost;
    }
}
